import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const Container = styled(motion.div)`
  display: none;
  @media (max-width: 991px) {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  margin-top: auto;
`;

export const Content = styled(motion.div).attrs(() => ({
  animate: { opacity: 1 },
  transition: { delay: 0.5, duration: 0.25 },
  initial: { opacity: 0 },
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Nav = styled(motion.nav)`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 31px;
  width: 100%;
  margin-bottom: auto;
`;

export const LinkWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NavLink = styled(motion(Link))`
  width: fit-content;
  height: 31px;
  font: var(--font43);
  letter-spacing: -3.7px;
  color: var(--color-black);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 4px;
  background-color: var(--color-greyEC);
  transition-property: height background-color;
  transition-duration: 0.3s;
  &:active {
    background-color: var(--color-yellow);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-yellow);
    }
  }
  &.active {
    background-color: var(--color-yellow);
    height: 90px;
  }
`;

export const Line = styled(motion.div).attrs(({ $isActive }) => ({
  animate: { flex: $isActive ? '1 1 auto' : '0 0 auto' },
  transition: { duration: 0.5 },
  initial: { flex: $isActive ? '1 1 auto' : '0 0 auto' },
  layoutScroll: true,
}))`
  height: 3px;
  &:after {
    content: '';
    display: block;
    height: 100%;
    width: ${({ $isSubActive }) => ($isSubActive ? 100 : 0)}%;
    transition: width 1s;
    background-color: var(--color-yellow);
  }
`;
