import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const Container = styled(motion.div)`
  display: grid;
  grid-template-columns: 50% auto;
`;

export const Line = styled.div`
  grid-column: 1/3;
  grid-row: 1/2;
  height: 4px;
  width: ${({ $isActive }) => ($isActive ? '100%' : '0%')};
  background-color: var(--color-yellow);
  transition: width 1s;
`;

export const NavLink = styled(Link)`
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: start;
  display: block;
  width: auto;
  height: 34px;
  background-color: var(--color-greyEC);
  font: var(--font48);
  letter-spacing: -1.75px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  color: var(--color-black);
  padding: 0 5px;
  transition-property: background-color 0.3s;
  &:active {
    background-color: var(--color-yellow);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-yellow);
    }
  }
  &.active {
    background-color: var(--color-yellow);
  }
`;

export const Wrapper = styled(motion.div).attrs(() => ({
  initial: { height: 0, opacity: 0 },
  animate: { height: 'auto', opacity: 1 },
  transition: { duration: 0.5 },
  exit: { height: 0, opacity: 0 },
}))`
  grid-column: 2/3;
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 10px;
`;
