import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, NavLink } from './styles';

const Navigation = ({ routes, isIntro, stopIntro }) => {
  const onClick = useCallback(() => {
    if (isIntro) stopIntro();
  }, [isIntro, stopIntro]);

  return (
    <Container layoutScroll>
      {routes.map(({ id, to, title }) => (
        <NavLink key={id} to={to} onClick={onClick}>
          {title}
        </NavLink>
      ))}
    </Container>
  );
};

Navigation.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  isIntro: PropTypes.bool.isRequired,
  stopIntro: PropTypes.func.isRequired,
};

export default Navigation;
