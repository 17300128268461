import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from '@/assets/svg/ArrowRight';

import { Container, Wrapper, HomeLink, Button, Span, MenuLink } from './styles';

const Header = ({ isActive, onAnimationComplete, isIntro, stopIntro, title }) => {
  const onClick = useCallback(() => {
    if (isIntro) stopIntro();
  }, [isIntro, stopIntro]);

  return (
    <Container $isActive={isActive} onAnimationComplete={onAnimationComplete}>
      <Wrapper layoutScroll>
        <HomeLink to="/" onClick={onClick} layoutScroll>
          {title}
        </HomeLink>
      </Wrapper>
      {isIntro ? (
        <Button type="button" onClick={stopIntro}>
          <Span>Weiter</Span>
          <Icon />
        </Button>
      ) : (
        <MenuLink to="/" activeClassName="active">
          Menu
        </MenuLink>
      )}
    </Container>
  );
};

Header.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onAnimationComplete: PropTypes.func.isRequired,
  isIntro: PropTypes.bool.isRequired,
  stopIntro: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
