import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ContentItem from './ContentItem';
import { Container, Nav, NavLink, Wrapper } from './styles';

const DesktopContent = ({ routes, location, children }) => {
  const subRoutes = useMemo(
    () =>
      routes.reduce((acc, { id, items }) => {
        if (items) acc[id] = items;

        return acc;
      }, {}),
    [routes]
  );
  const isIndex = location.pathname === '/';
  const key = useMemo(() => location.pathname.split('/')[1], [location.pathname]);
  const subNavigation = subRoutes[key];

  return (
    <Container layoutScroll>
      <Nav layoutScroll>
        {routes.map(({ id, to, title }) => (
          <NavLink key={id} to={to} activeClassName="active" partiallyActive>
            {title}
          </NavLink>
        ))}
      </Nav>
      <Wrapper $isIndex={isIndex}>
        {subNavigation?.length > 0
          ? subNavigation.map(({ to, title }) => (
              <ContentItem key={to} to={to} title={title} isActive={to === location.pathname}>
                {children}
              </ContentItem>
            ))
          : children}
      </Wrapper>
    </Container>
  );
};

DesktopContent.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          to: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired
      ),
    }).isRequired
  ).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default DesktopContent;
