const React = require('react');
const { StyleSheetManager } = require('styled-components');

const Layout = require('./src/components/Layout').default;
const GlobalStyles = require('./src/components/GlobalStyles').default;

exports.wrapRootElement = ({ element }) => (
  <StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV === 'development'}>
    <GlobalStyles />
    {element}
  </StyleSheetManager>
);

exports.wrapPageElement = ({ element, props: { location } }) => <Layout location={location}>{element}</Layout>;
