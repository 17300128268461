import React from 'react';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import { Container, NavLink, Line, Wrapper } from './styles';

const ContentItem = ({ to, title, isActive, children }) => (
  <AnimatePresence>
    <Container key={to}>
      <NavLink to={to} activeClassName="active" layoutScroll>
        {title}
      </NavLink>
      {isActive && <Line />}
      <AnimatePresence>{isActive && <Wrapper>{children}</Wrapper>}</AnimatePresence>
    </Container>
  </AnimatePresence>
);

ContentItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContentItem;
