import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const Container = styled(motion.footer).attrs(({ $isOpened }) => ({
  animate: { flex: $isOpened ? '1 1 auto' : '0 1 auto' },
  transition: { duration: 0.5 },
  initial: { flex: $isOpened ? '1 1 auto' : '0 1 auto' },
  layoutScroll: true,
}))`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--max-width);
  overflow: hidden;
`;

export const Head = styled(motion.div).attrs(({ $isHidden }) => ({
  animate: { height: $isHidden ? 0 : 'auto' },
  transition: { duration: 0.5 },
  initial: { height: $isHidden ? 0 : 'auto' },
  layoutScroll: true,
}))`
  display: grid;
  grid-template-columns: auto 21% 300px;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  padding: ${({ $isHidden }) => ($isHidden ? 0 : 4)}px 10px;
  @media (max-width: 991px) {
    display: flex;
    justify-content: ${({ $isActive }) => ($isActive ? 'flex-start' : 'space-between')};
    align-items: ${({ $isActive }) => ($isActive ? 'flex-start' : 'center')};
    column-gap: 8px;
    padding: ${({ $isHidden }) => ($isHidden ? 0 : 5)}px 5px;
  }
`;

export const NavLink = styled(motion(Link))`
  width: fit-content;
  font: var(--font15);
  line-height: 12px;
  letter-spacing: -0.34px;
  color: var(--color-black);
  text-transform: uppercase;
  text-decoration: none;
  background-color: ${({ $isActive }) => ($isActive ? 'var(--color-yellow)' : 'transparent')};
  transition: background-color 0.3s;
  &:active {
    background-color: var(--color-yellow);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-yellow);
    }
  }
  @media (max-width: 991px) {
    position: relative;

    background-color: var(--color-greyEC);
    &:after {
      content: '';
      position: absolute;
      inset: auto 0 0 0;
      height: 3px;
      width: 0;
      background-color: var(--color-greyEC);
    }
    &.active {
      height: 36px;
      background-color: var(--color-yellow);
      &:after {
        width: 100vw;
        background-color: var(--color-yellow);
      }
    }
  }
`;

const textStyle = `
  font: var(--font15);
  line-height: 12px;
  letter-spacing: -0.34px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Copyright = styled(motion.p)`
  display: ${({ $isMobile }) => ($isMobile ? 'none' : 'flex')};
  align-items: center;
  width: fit-content;
  max-height: 12px;
  background-color: ${({ $isActive }) => ($isActive ? 'var(--color-yellow)' : 'transparent')};
  transition: background-color 0.5s;
  &:before {
    content: '${({ $content }) => $content}';
    ${textStyle}
  }
  &:after {
    display: none;
    content: '${({ $shortContent }) => $shortContent}';
    ${textStyle}
  }
  @media (max-width: 991px) {
    display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
    background-color: transparent;
    padding: ${({ $isMobile }) => ($isMobile ? '5px' : 0)};
  }
  @media (max-width: 529px) {
    &:before {
      display: ${({ $isMobile }) => ($isMobile ? 'block' : 'none')};
    }
    &:after {
      display: ${({ $isMobile }) => ($isMobile ? 'none' : 'block')};
    }
  }
`;

export const Wrapper = styled(motion.div).attrs(() => ({
  initial: { height: 0 },
  animate: { height: 'auto' },
  transition: { duration: 0.5 },
  exit: { height: 0 },
  layoutScroll: true,
}))`
  width: 100%;
`;
