import React, { useState, useCallback, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Header from './Header';
import Section from './Section';
import Navigation from './Navigation';
import DesktopContent from './DesktopContent';
import MobileContent from './MobileContent';
import Footer from './Footer';
import { Main } from './styles';

const Layout = ({ location, children }) => {
  const { dataJson } = useStaticQuery(graphql`
    query {
      dataJson(jsonId: { eq: "app" }) {
        sections {
          id
          title
          routes {
            id
            to
            title
            items {
              to
              title
            }
          }
          isGrey
        }
      }
    }
  `);
  const footerLink = location.pathname === '/datenschutz/' || location.pathname === '/impressum/';
  const [index, setIndex] = useState(location.pathname === '/' ? 0 : 1);
  const [isIntro, setIntro] = useState(location.pathname === '/');
  const stopIntro = useCallback(() => {
    setIndex(1);
    setIntro(false);
  }, []);
  const onAnimationComplete = useCallback(
    ({ flex }) => {
      if (isIntro && flex === '1 1 auto') {
        setIndex(($) => {
          let next = $ + 1;

          if (window.innerWidth < 992 && dataJson.sections[next]?.routes) {
            next += 1;
          }

          if (next >= dataJson.sections.length) {
            stopIntro();

            return $;
          }
          return next;
        });
      }
    },
    [isIntro, dataJson.sections, stopIntro]
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && isIntro) {
      const handler = () => {
        stopIntro();

        document.body.removeEventListener('click', handler);
      };

      document.body.addEventListener('click', handler);
    }
  }, [isIntro, stopIntro]);

  return (
    <>
      <Main>
        {dataJson.sections.map(({ id, title, routes, isGrey }) =>
          id === 0 ? (
            <Header
              key={id}
              isActive={index === id && !footerLink}
              onAnimationComplete={onAnimationComplete}
              isIntro={isIntro}
              stopIntro={stopIntro}
              title={title}
            />
          ) : (
            <Section
              key={id}
              isActive={index === id && !footerLink}
              onAnimationComplete={onAnimationComplete}
              isMobileHidden={isIntro ? Boolean(routes) : id > 1}
              title={title}
              isGrey={isGrey}
            >
              {routes &&
                (isIntro || footerLink ? (
                  <Navigation routes={routes} isIntro={isIntro} stopIntro={stopIntro} />
                ) : (
                  <>
                    <DesktopContent routes={routes} location={location}>
                      {children}
                    </DesktopContent>
                    <MobileContent routes={routes} location={location}>
                      {children}
                    </MobileContent>
                  </>
                ))}
            </Section>
          )
        )}
      </Main>
      <Footer isHidden={isIntro}>{footerLink && children}</Footer>
    </>
  );
};

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
