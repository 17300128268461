import { createGlobalStyle } from 'styled-components';

import baselGroteskLightWoff from '@/assets/fonts/baselGroteskLight.woff';
import baselGroteskLightWoff2 from '@/assets/fonts/baselGroteskLight.woff2';
import baselGroteskRegularWoff from '@/assets/fonts/baselGroteskRegular.woff';
import baselGroteskRegularWoff2 from '@/assets/fonts/baselGroteskRegular.woff2';
import baselGroteskThinWoff from '@/assets/fonts/baselGroteskThin.woff';
import baselGroteskThinWoff2 from '@/assets/fonts/baselGroteskThin.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: "Basel-Grotesk-Light";
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(${baselGroteskLightWoff}) format("woff"), url(${baselGroteskLightWoff2}) format("woff2");
  }
  @font-face {
    font-family: "Basel-Grotesk-Regular";
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(${baselGroteskRegularWoff}) format("woff"), url(${baselGroteskRegularWoff2}) format("woff2");
  }
  @font-face {
    font-family: "Basel-Grotesk-Thin";
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(${baselGroteskThinWoff}) format("woff"), url(${baselGroteskThinWoff2}) format("woff2");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 375px;
  }
  body, #___gatsby  {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  #gatsby-focus-wrapper  {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  button {
    appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  input, textarea {
    appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
    &:disabled {
      cursor: not-allowed;
    }
  }
  :root {
    --color-white: #fff;
    --color-black: #000;
    --color-yellow: #eeff66;
    --color-greyEC: #ececec;

    --font112: 100 112px/80px "Basel-Grotesk-Thin", sans-serif;
    --font48: 300 48px/34px "Basel-Grotesk-Light", sans-serif;
    --font43: 300 43px/31px "Basel-Grotesk-Light", sans-serif;
    --font30: 300 30px/32px "Basel-Grotesk-Light", sans-serif;
    --font24: 400 24px/26px "Basel-Grotesk-Regular", sans-serif;
    --font15: 400 15px/16px "Basel-Grotesk-Regular", sans-serif;

    --vh: 1dvh;
    --max-width: 2048px;

    @supports not (height: 1dvh) {
      --vh: 1vh;
    }
  }
`;
