import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const Container = styled(motion.nav)`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  min-height: 34px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  width: fit-content;
  height: 34px;
  font: var(--font48);
  letter-spacing: -1.75px;
  color: var(--color-black);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 5px;
  background-color: var(--color-greyEC);
  transition: background-color 0.3s;
  &:active {
    background-color: var(--color-yellow);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-yellow);
    }
  }
`;
