import React from 'react';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import { Container, Line, NavLink, Wrapper } from './styles';

const ContentItem = ({ to, title, isActive, children }) => (
  <Container key={to} layoutScroll>
    <Line $isActive={isActive} />
    <NavLink to={to} activeClassName="active">
      {title}
    </NavLink>
    <AnimatePresence>{isActive && <Wrapper>{children}</Wrapper>}</AnimatePresence>
  </Container>
);

ContentItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContentItem;
