import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper, Title } from './styles';

const Section = ({ isActive, onAnimationComplete, isMobileHidden, title, isGrey, children }) => (
  <Container $isActive={isActive} $isMobileHidden={isMobileHidden} onAnimationComplete={onAnimationComplete}>
    {title && (
      <Wrapper $isGrey={isGrey} layoutScroll>
        <Title layoutScroll>{title}</Title>
      </Wrapper>
    )}
    {children}
  </Container>
);

Section.defaultProps = {
  title: null,
  isGrey: null,
  children: null,
};
Section.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onAnimationComplete: PropTypes.func.isRequired,
  isMobileHidden: PropTypes.bool.isRequired,
  title: PropTypes.string,
  isGrey: PropTypes.bool,
  children: PropTypes.node,
};

export default Section;
