import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.section).attrs(({ $isActive }) => ({
  animate: { flex: $isActive ? '1 1 auto' : '0 1 auto' },
  transition: { duration: 0.75, ease: 'easeOut' },
  initial: { flex: $isActive ? '1 1 auto' : '0 1 auto' },
  layoutScroll: true,
}))`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    display: ${({ $isMobileHidden }) => ($isMobileHidden ? 'none' : 'flex')};
  }
`;

export const Wrapper = styled(motion.div)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: var(--color-${({ $isGrey }) => ($isGrey ? 'greyEC' : 'yellow')});
`;

export const Title = styled(motion.h1)`
  max-height: 80px;
  font: var(--font112);
  letter-spacing: -9px;
  color: var(--color-black);
  text-transform: uppercase;
  padding: 0 10px;
  overflow: hidden;
  @media (max-width: 991px) {
    font: var(--font43);
    letter-spacing: -3.7px;
    padding: 0 4px;
  }
`;
