import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const Container = styled(motion.header).attrs(({ $isActive }) => ({
  animate: { flex: $isActive ? '1 1 auto' : '0 1 auto' },
  transition: { duration: 0.75, ease: 'easeOut' },
  initial: { flex: $isActive ? '1 1 auto' : '0 1 auto' },
  layoutScroll: true,
}))`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: var(--color-yellow);
`;

export const HomeLink = styled(motion(Link))`
  font: var(--font112);
  letter-spacing: -9px;
  color: var(--color-black);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 10px;
  overflow: hidden;
  @media (max-width: 991px) {
    font: var(--font43);
    letter-spacing: -3.7px;
    padding: 0 4px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 3px;
  height: 11px;
  margin: 8px;
  color: var(--color-black);
  transition: background-color 0.3s;
  &:active {
    background-color: var(--color-yellow);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-yellow);
    }
  }
`;

export const Span = styled.span`
  font: var(--font15);
  letter-spacing: -0.34px;
  color: inherit;
  text-transform: uppercase;
`;

export const MenuLink = styled(Link)`
  background-color: var(--color-greyEC);
  font: var(--font43);
  letter-spacing: -3.7px;
  color: var(--color-black);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 4px;
  transition: background-color 0.3s;
  &:active {
    background-color: var(--color-yellow);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-yellow);
    }
  }
  &.active {
    background-color: var(--color-yellow);
  }
  @media (min-width: 992px) {
    display: none;
  }
`;
