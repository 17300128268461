import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import { Container, Head, NavLink, Copyright, Wrapper } from './styles';

const Footer = ({ isHidden, children }) => {
  const isOpened = Boolean(children);
  const year = useMemo(() => new Date().getFullYear(), []);
  const { dataJson, site } = useStaticQuery(graphql`
    query {
      dataJson(jsonId: { eq: "footer" }) {
        routes {
          id
          title
          url
        }
      }
      site {
        siteMetadata {
          title
          shortTitle
        }
      }
    }
  `);

  return (
    <Container $isOpened={isOpened}>
      <Head $isHidden={isHidden} $isActive={isOpened}>
        {dataJson.routes.map(({ id, title, url }) => (
          <NavLink key={id} to={url} activeClassName="active" $isActive={isOpened} layoutScroll>
            {title}
          </NavLink>
        ))}
        <Copyright
          $isActive={isOpened}
          $isHidden={isOpened}
          $content={`© ${year} ${site.siteMetadata.title}`}
          $shortContent={`© ${year} ${site.siteMetadata.shortTitle}`}
        />
      </Head>
      <AnimatePresence>
        {children && (
          <Wrapper>
            {children}
            <Copyright
              $isMobile
              $content={`© ${year} ${site.siteMetadata.title}`}
              $shortContent={`© ${year} ${site.siteMetadata.shortTitle}`}
              layoutScroll
            />
          </Wrapper>
        )}
      </AnimatePresence>
    </Container>
  );
};

Footer.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Footer;
