import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: {
    opacity: { delay: 0.25, duration: 0.25 },
  },
  exit: { opacity: 0 },
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  overflow: hidden;
`;

export const NavLink = styled(motion(Link))`
  display: block;
  width: fit-content;
  height: 31px;
  background-color: var(--color-greyEC);
  font: var(--font43);
  letter-spacing: -3.7px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  color: var(--color-black);
  padding: 0 4px;
  transition: background-color 0.3s;
  &:active {
    background-color: var(--color-yellow);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-yellow);
    }
  }
  &.active {
    background-color: var(--color-yellow);
    margin-right: auto;
  }
`;

export const Line = styled(motion.div).attrs(() => ({
  initial: { width: 0 },
  animate: { width: '100%' },
  transition: { duration: 0.5 },
  exit: { width: 0 },
}))`
  align-self: flex-start;
  height: 3px;
  width: 100%;
  background-color: var(--color-yellow);
  transform: translateY(-3px);
  z-index: -1;
`;

export const Wrapper = styled(motion.div).attrs(() => ({
  initial: { height: 0 },
  animate: { height: 'auto' },
  transition: { duration: 0.5 },
  exit: { height: 0 },
  layoutScroll: true,
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
