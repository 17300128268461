import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ContentItem from './ContentItem';
import { Container, Wrapper, Content, Nav, LinkWrap, NavLink, Line } from './styles';

const MobileContent = ({ routes, location, children }) => {
  const subRoutes = useMemo(
    () =>
      routes.reduce((acc, { id, items }) => {
        if (items) acc[id] = items;

        return acc;
      }, {}),
    [routes]
  );
  const [, key, subKey] = useMemo(() => location.pathname.split('/'), [location.pathname]);
  const subNavigation = subRoutes[key];

  return (
    <Container layoutScroll>
      <Wrapper>
        {subNavigation?.length > 0 ? (
          subNavigation.map(({ to, title }) => (
            <ContentItem key={to} to={to} title={title} isActive={to === location.pathname}>
              {children}
            </ContentItem>
          ))
        ) : (
          <Content>{children}</Content>
        )}
      </Wrapper>
      <Nav>
        {routes.map(({ id, to, title }) => (
          <LinkWrap key={id}>
            <NavLink to={to} activeClassName="active" partiallyActive layoutScroll>
              {title}
            </NavLink>
            <Line $isActive={key === id} $isSubActive={(subKey && key === id) || id === 'kontakt'} />
          </LinkWrap>
        ))}
      </Nav>
    </Container>
  );
};

MobileContent.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          to: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired
      ),
    }).isRequired
  ).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default MobileContent;
