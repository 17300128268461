import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

export const Container = styled(motion.div)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const Nav = styled(motion.nav)`
  display: flex;
  justify-content: space-between;
  align-items: normal;
  column-gap: 10px;
  min-height: 150px;
`;

export const NavLink = styled(Link)`
  width: fit-content;
  height: 34px;
  font: var(--font48);
  letter-spacing: -1.75px;
  color: var(--color-black);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 5px;
  background-color: var(--color-greyEC);
  transition-property: height background-color;
  transition-duration: 0.3s;
  &:active {
    background-color: var(--color-yellow);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-yellow);
    }
  }
  &.active {
    background-color: var(--color-yellow);
    height: 150px;
  }
`;

export const Wrapper = styled(motion.div).attrs(({ $isIndex }) => ({
  animate: { height: $isIndex ? 0 : 'auto' },
  transition: { duration: 0.5 },
  initial: { height: $isIndex ? 0 : 'auto' },
  layoutScroll: true,
}))`
  display: flex;
  flex-direction: column;
  gap: 4px;
  &:after {
    content: '';
    min-height: 50px;
  }
`;
