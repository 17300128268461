import styled from 'styled-components';

export const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  max-width: var(--max-width);
  @media (max-width: 991px) {
    row-gap: 8px;
  }
`;
